<template>
  <q-intersection transition="slide-down" v-show="this.show">
    <q-table
      flat
      bordered
      :data="reports"
      row-key="id"
      :columns="columns"
      :pagination="pagination"
      :visible-columns="visibleColumns"
    >
      <template v-slot:top-left>
        <div class="row items-center">
          <div class="text-center q-mr-sm">Ordernar por:</div>
          <q-select
            :label="'Coluna'"
            v-model="sorting.column"
            style="min-width: 150px"
            outlined
            dense
            :options="columns.filter((item) => item.label != 'Índice')"
            clearable
          />
          <q-toggle
            :label="`Crescente | Decrescente`"
            true-value="desc"
            false-value="asc"
            color="blue"
            v-model="sorting.order"
          />
          <q-btn class="q-ml-md" color="positive" no-caps unelevated @click="retrieveWithSorting">Ordenar</q-btn>
        </div>
      </template>

      <template v-slot:top-right>
        <q-btn
          outline
          color="grey-3"
          size="12px"
          text-color="grey-8"
          icon-right="archive"
          class="q-mr-sm q-py-xs"
          no-caps
          @click="exportClickReport()"
          unelevated
        >
          <q-tooltip> Exportar como CSV</q-tooltip>
        </q-btn>
        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          display-value="Personalizar Colunas"
          emit-value
          map-options
          :options="columns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        >
          <template v-slot:option="{ itemProps, itemEvents, opt, selected, toggleOption }">
            <q-item v-bind="itemProps" v-on="itemEvents">
              <q-item-section>
                <q-item-label v-html="opt.label"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </template>

      <template v-slot:header-cell-valor_total_pendente="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor total de conversões geradas no site do anunciante que ainda está pendente. </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-valor_total_aprovado="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor total de conversões geradas no site do anunciante que já está aprovado. </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-cpc_pendente="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPC que ainda está pendente. </q-tooltip>
          </q-icon>
        </q-th>
      </template>
      <template v-slot:header-cell-cpc_aprovado="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPC que já está aprovado. </q-tooltip>
          </q-icon>
        </q-th>
      </template>

      <template v-slot:header-cell-comissao_pendente="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPA/CPL que ainda está pendente. </q-tooltip>
          </q-icon>
        </q-th>
      </template>

      <template v-slot:header-cell-comissao_aprovada="props">
        <q-th :props="props">
          {{ props.col.label }}
          <q-icon name="mdi-help-circle-outline" class="cursor-pointer" size="1.3em">
            <q-tooltip> Valor da sua comissão CPA/CPL que já está aprovado. </q-tooltip>
          </q-icon>
        </q-th>
      </template>

      <!-- <template v-slot:bottom-row v-if="reports.length > 1">
                <q-tr class="text-bold">
                    <q-td> TOTAL: </q-td>
                    <q-td> {{ reportsTotal.impressoes }} </q-td>
                    <q-td> {{ reportsTotal.total }} </q-td>
                    <q-td> {{ reportsTotal.visitantes_unicos }} </q-td>
                    <q-td> - </q-td>
                    <q-td> {{ reportsTotal.cadastros_pendentes }} </q-td>
                    <q-td> {{ reportsTotal.cadastros_aprovados }} </q-td>
                    <q-td> - </q-td>
                    <q-td> {{ reportsTotal.vendas_pendentes }} </q-td>
                    <q-td> {{ reportsTotal.vendas_aprovadas }} </q-td>
                    <q-td> - </q-td>
                    <q-td>
                        {{ parseMoney(reportsTotal.valor_total_pendente) }}
                    </q-td>
                    <q-td>
                        {{ parseMoney(reportsTotal.valor_total_aprovado) }}
                    </q-td>
                    <q-td> {{ parseMoney(reportsTotal.cpc_pendente) }} </q-td>
                    <q-td> {{ parseMoney(reportsTotal.cpc_aprovado) }} </q-td>
                    <q-td>
                        {{ parseMoney(reportsTotal.comissao_pendente) }}
                    </q-td>
                    <q-td>
                        {{ parseMoney(reportsTotal.comissao_aprovada) }}
                    </q-td>
                </q-tr>
            </template> -->

      <template v-slot:body-cell-ctr="props">
        <q-td :props="props">
          {{ `${props.row.ctr}%` }}
        </q-td>
      </template>

      <template v-slot:body-cell-scr="props">
        <q-td :props="props">
          {{ `${props.row.scr}%` }}
        </q-td>
      </template>

      <template v-slot:body-cell-lcr="props">
        <q-td :props="props">
          {{ `${props.row.lcr}%` }}
        </q-td>
      </template>

      <template v-slot:body-cell-transaction_amount_pending="props">
        <q-td :props="props">
          {{ `R$ ${props.row.transaction_amount_pending}` }}
        </q-td>
      </template>

      <template v-slot:body-cell-transaction_amount_approved="props">
        <q-td :props="props">
          {{ `R$ ${props.row.transaction_amount_approved}` }}
        </q-td>
      </template>

      <template v-slot:body-cell-cost_cpc_pending="props">
        <q-td :props="props">
          {{ `R$ ${props.row.cost_cpc_pending}` }}
        </q-td>
      </template>

      <template v-slot:body-cell-cost_cpc_approved="props">
        <q-td :props="props">
          {{ `R$ ${props.row.cost_cpc_approved}` }}
        </q-td>
      </template>

      <template v-slot:body-cell-cost_pending="props">
        <q-td :props="props">
          {{ `R$ ${props.row.cost_pending}` }}
        </q-td>
      </template>

      <template v-slot:body-cell-cost_approved="props">
        <q-td :props="props">
          {{ `R$ ${props.row.cost_approved}` }}
        </q-td>
      </template>

      <template v-slot:bottom>
        <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
          Total:{{ reqPagination.total }}
          <q-space />
          Linhas por página: &nbsp;
          <q-select
            v-model="pagination.rowsPerPage"
            :options="[10, 25, 50]"
            options-dense
            menu-shrink
            dense
            borderless
          />
          <q-pagination
            unelevated
            v-model="currentPage"
            color="primary"
            :max="reqPagination.last_page"
            :max-pages="6"
            boundary-numbers
            direction-links
          />
        </q-toolbar>
      </template>
    </q-table>
  </q-intersection>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';

export default {
  name: 'AffiliateListClickReports',
  mixins: [ReportMixin],

  data() {
    return {
      sorting: {
        column: null,
        order: 'desc'
      },
      visibleColumns: null,
      columns: [
        {
          name: 'indice',
          label: 'Índice',
          align: 'left',
          field: 'indice',
          sortable: false,
          default: true
        },
        {
          name: 'impressions',
          label: 'Impressões',
          align: 'left',
          field: 'impressions',
          sortable: false,
          default: true
        },
        {
          name: 'total_clicks',
          label: 'Cliques totais',
          align: 'left',
          field: 'total_clicks',
          sortable: false,
          default: true
        },
        {
          name: 'unique_clicks',
          label: 'Cliques únicos',
          align: 'left',
          field: 'unique_clicks',
          sortable: false,
          default: true
        },
        {
          name: 'ctr',
          label: 'CTR %',
          align: 'left',
          field: 'ctr',
          sortable: false,
          default: false
        },
        {
          name: 'pending_leads',
          label: 'Cadastros Pendentes',
          align: 'left',
          field: 'pending_leads',
          sortable: false,
          default: true
        },
        {
          name: 'approved_leads',
          label: 'Cadastros Aprovados',
          align: 'left',
          field: 'approved_leads',
          sortable: false,
          default: false
        },
        {
          name: 'lcr',
          label: 'LCR %',
          align: 'left',
          field: 'lcr',
          sortable: false,
          default: false
        },
        {
          name: 'pending_sales',
          label: 'Vendas Pendentes',
          align: 'left',
          field: 'pending_sales',
          sortable: false,
          default: true
        },
        {
          name: 'approved_sales',
          label: 'Vendas Aprovadas',
          align: 'left',
          field: 'approved_sales',
          sortable: false,
          default: false
        },
        {
          name: 'scr',
          label: 'SCR %',
          align: 'left',
          field: 'scr',
          sortable: false,
          default: false
        },
        {
          name: 'transaction_amount_pending',
          label: 'Valor Total Pendente',
          align: 'left',
          field: 'transaction_amount_pending',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: false
        },
        {
          name: 'transaction_amount_approved',
          label: 'Valor Total Aprovado',
          align: 'left',
          field: 'transaction_amount_approved',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: false
        },
        {
          name: 'cost_cpc_pending',
          label: 'Comissão Cliques Pendente',
          align: 'left',
          field: 'cost_cpc_pending',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: false
        },
        {
          name: 'cost_cpc_approved',
          label: 'Comissão Cliques Aprovado',
          align: 'left',
          field: 'cost_cpc_approved',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: false
        },
        {
          name: 'cost_pending',
          label: 'Comissão Pendente',
          align: 'left',
          field: 'cost_pending',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: true
        },
        {
          name: 'cost_approved',
          label: 'Comissão Aprovada',
          align: 'left',
          field: 'cost_approved',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: true
        },
        {
          name: 'pending_epc',
          label: 'EPC Pendente',
          align: 'left',
          field: 'pending_epc',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: false
        },
        {
          name: 'approved_epc',
          label: 'EPC Aprovado',
          align: 'left',
          field: 'approved_epc',
          sortable: false,
          format: (val) => this.parseMoney(val),
          default: false
        }
      ],

      pagination: {
        sortBy: 'created_at',
        descending: true,
        rowsPerPage: 10
      }
    };
  },
  methods: {
    async exportClickReport() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          'redirect?app=report&path=/api/report/affiliate_click_report_csv?' + this.filterReports
        );
        if (status === 200 || status === 201) {
          console.log(data);
          const filteredColumns = this.columns.filter((column) => column.default !== false);
          this.exportTableCsv(data, filteredColumns, 'click-reports');
          this.successNotify('Exportado com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async retrieveWithSorting() {
      if (!this.sorting.column) {
        this.errorNotify('Selecione uma coluna para ordenação!');
        return;
      }
      let params = this.filterReports.split('%26');
      if (this.sorting.column != null) {
        let sortIndex = params.findIndex((param) => param.includes('sort'));
        sortIndex != -1
          ? (params[sortIndex] = `sort=${this.sorting.column.field}%7C${this.sorting.order}`)
          : params.push(` sort=${this.sorting.column.field}%7C${this.sorting.order}`);
      } else {
        params = params.filter((val) => !val.includes('sort'));
      }
      this.ActionSetFilterReports(params.join('%26'));
      this.getConsolidatedClickReportAffiliate();
    }
  },
  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getConsolidatedClickReportAffiliate(
          `page=${this.currentPage}`,
          `%26per_page=${this.pagination.rowsPerPage}`
        );
      }
    },

    'pagination.rowsPerPage': function () {
      this.getConsolidatedClickReportAffiliate(
        `page=${this.currentPage}`,
        `%26per_page=${this.pagination.rowsPerPage}`
      );
      this.ActionSetReports();
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    },
    show() {
      return typeof this.reports === 'undefined' ? false : true;
    }
  },

  created() {
    this.ActionSetFilterReports();
    this.ActionSetReports();
    this.visibleColumns = this.columns.filter((column) => column.default !== false).map((column) => column.name);
    this.sorting.column = this.columns.find((column) => column.field == 'cost_pending');
  }
};
</script>
