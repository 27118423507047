<template>
  <q-layout>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('consolidated_report') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el :label="$t('click_report')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />

    <q-page padding>
      <AffiliateHeaderClickReports />
      <AffiliateListClickReports />
    </q-page>
  </q-layout>
</template>

<script>
import AffiliateHeaderClickReports from '../../components/reports/affiliate/AffiliateHeaderClickReportsConsolidated';
import AffiliateListClickReports from '../../components/reports/affiliate/AffiliateListClickReportsConsolidated';

export default {
  components: { AffiliateHeaderClickReports, AffiliateListClickReports },
  name: 'AffiliateClickReportsConsolidated'
};
</script>
